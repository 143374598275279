import * as React from "react"
import Spacer from "../components/spacer"
import Newsletter from "../components/newsletter"
import scrollTo from "./scrollTo"

const Footer = () => (
  <div className='colophon white p40'>
    <TopBar />
    <Spacer/>
    <MiddleBar />
    <Spacer className='m-hide'/>
    <BottomBar />
  </div>
) 

export default Footer

const TopBar = () => {
  return (
    <div className='flex m-wrap mb40 mt40'>
      <div className='w-50 m-100 fade--in' data-sal>
        <div className='emblem'/>
      </div>
      <div className='w-50 m-100 m-mt40 m-pt20'>
        <div className='flex max-350 fade--in' data-sal>
          <div className='w-50'>
            <p className='m0 h4 mb20'>About</p>
            <div className='medium m-reg'>
              <p role='presentation' onClick={() => scrollTo('#who')} className='co-link m0 mb15 m-mb10'>Who We Are</p>
              <p role='presentation' onClick={() => scrollTo('#projects')} className='co-link m0 m-mb10 mb15'>Projects</p>
              <p role='presentation' onClick={() => scrollTo('#team')} className='co-link m0'>Our Team</p>
            </div>
          </div>
          <div className='w-50'>
            <p className='m0 h4 mb20'>Works</p>
            <div className='medium m-reg'>
              <p role='presentation' onClick={() => scrollTo('#past')} className='co-link m0 m-mb10 mb15' >Past Projects</p>
              <p role='presentation' onClick={() => scrollTo('#testimonial')} className='co-link m-mb10 m0 mb15'>Testimonials</p>
              <p role='presentation' onClick={() => scrollTo('#contact')} className='co-link m0'>Contact</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MiddleBar = () => {
  return (
    <div className='flex m-wrap mb40 mt40'>
      <div className='w-50 m-100 fade--in' data-sal>
        <p className='m0 grey-200 m-reg medium lh2'>Based in Sunshine Coast<br/> Queensland, Australia</p>
        <div className='mt20 '>
          <a aria-label='facebook' href='https://www.facebook.com/WaltBuilt' target='_blank' rel='noreferrer'><span className='icon facebook'/></a>
          <a className='ml10' aria-label='facebook' href='https://www.instagram.com/waltconstruction' target='_blank' rel='noreferrer'><span className='icon instagram'/></a>
        </div>
      </div>
      <div className='w-50 m-100 m-mt40 m-pt20 fade--in' data-sal>
        <p className='m0 h4 '>Get Updates</p>
        <p className='grey-200 medium m-reg mt10 m0 mb30'>Drop us your email to learn what’s next.</p>
        <Newsletter />
      </div>
    </div> 
  ) 
}

const BottomBar = () => {
  return (
    <div className='bottom-bar fade--in' data-sal>
      <div className=' text-center m-xs flex pt20'>
        <div className='grey-100'>© {new Date().getFullYear()} &middot; Copyright Walt Construction</div>
      </div>
    </div>
  )
}